<template>

  <CCard>

    <CCardBody>
      <profile-verification></profile-verification>
    </CCardBody>

  </CCard>

</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  profileVerifications,
  users,
  verificationStatus,
  profile,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import required_span from "/src/components/layouts/general/required-span";
import VueToasted from "vue-toasted";
import "viewerjs/dist/viewer.css";
import moment from "moment";
import profileVerification from "./tab/ProfileVerification";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

// Vue.use(VueViewer)
Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "ProfileVerification",
  mixins: [Mixin],
  components: {
    required_span,
    profileVerification
  },
  data() {
    return {
      existResponse: false,
      submitted: false,
      largeModal: false,
      Disabled: true,
      regDocUrl: "",
      ext: "",
      userId: "",
      viewImage: "",
      err_msg: "",
      profileImage: "",
      registeredDoc: "",
      dismissCountDown: 0,
      userOptions: [],
      companyOptions: [],
      statusOptions: [],
      verifyDocs: [],
      self: this,
      module: profileVerifications,
      moduleProfile: profile,
      moduleUser: users,
      moduleVerificationStatus: verificationStatus,
      profile_verification: {
        name: "",
        address: "",
        registeredNumber: "",
        regDoc: "",
        status: "pending",
        companyIdentificationNumber: "",
        companyIdentificationName: "",
        userIdentificationNumber: "",
        userIdentificationName: "",
      },
    };
  },
  validations: {
    profile_verification: {
      name: {
        required,
      },
      address: {
        required,
      },
      registeredNumber: {
        required,
      },
      status: {
        required,
      },
    },
  },
  mounted() {
    store.commit("showLoader", false); // Loader Off
    let self = this;
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;

    axios
        .get(this.listUrlApi(this.moduleVerificationStatus))
        .then((response) => {
          self.subIndustryOptions = [];
          response.data.map(function (value, key) {
            self.statusOptions.push({value: value.value, label: value.label});
          });
        });

    self.profile_verification.status = {
      value: "pending",
      label: "Pending",
    };

    axios.get(this.viewUrlApi(this.module, id)).then((response) => {
      if (response.data.status === 200) {
        self.existResponse = false;

        const responseData = response.data.data;
        self.profile_verification.name = responseData.name;
        self.ext = responseData.ext; //redDoc ext
        self.profile_verification.address = responseData.address;
        self.profile_verification.registeredNumber =
            responseData.registeredNumber;
        self.profile_verification.regDate = responseData.regDate; //  moment(responseData.regDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
        self.profile_verification.userVerified = responseData.userVerified;
        self.profile_verification.companyVerified =
            responseData.companyVerified;
        self.profile_verification.domainVerified = responseData.domainVerified;
        self.registeredDoc = responseData.regDoc;
        self.verifyDocs = responseData.verifyDocs;
        self.userId = responseData.userId;
        self.profile_verification.company = responseData.company;

        const status = responseData.status;
        self.profile_verification.status = {
          'value': status,
          'label': status.charAt(0).toUpperCase() + status.slice(1),
        }
      } else {
        self.existResponse = true;
      }
    });
  },
  methods: {
    hide() {
      alert("sdf");
    },
    open(url) {
      let self = this;
      self.viewImage = url;
      //  fancyBox(e.target,this.images);
      self.largeModal = true;
    },

    viewRegDoc() {
      let self = this;
      const userId = self.userId;
      let routeData = this.$router.resolve({path: `/viewer/reg/${userId}`});
      window.open(routeData.href, "_blank");
    },

    viewPdf(id) {
      let routeData = this.$router.resolve({path: `/viewer/pdf/${id}`});
      window.open(routeData.href, "_blank");
    },
    viewDoc(id) {
      let routeData = this.$router.resolve({path: `/viewer/doc/${id}`});
      window.open(routeData.href, "_blank");
    },
    viewXls(id) {
      let routeData = this.$router.resolve({path: `/viewer/xls/${id}`});
      window.open(routeData.href, "_blank");
    },

    documentsIndex: function (index) {
      return index + 1;
    },
    getFormat: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    changedUserVerified(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.profile_verification.userVerified = 1;
      } else {
        self.profile_verification.userVerified = 0;
      }
    },

    changedCompanyVerified(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.profile_verification.companyVerified = 1;
      } else {
        self.profile_verification.companyVerified = 0;
      }
    },
    changedDomainVerified(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.profile_verification.domainVerified = 1;
      } else {
        self.profile_verification.domainVerified = 0;
      }
    },

    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    onSubmit() {
      let self = this;
      this.$v.profile_verification.$touch();
      if (this.$v.profile_verification.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        setTimeout(() => {
          let self = this;
          let username, status;

          username = self.$route.params.id;

          if (self.profile_verification.status.value !== "") {
            status = self.profile_verification.status.value;
          }
          const postData = {
            userId: username,
            registeredNumber: self.profile_verification.registeredNumber,
            name: self.profile_verification.name,
            address: self.profile_verification.address,
            regDate: self.profile_verification.regDate,
            userVerified: self.profile_verification.userVerified,
            companyVerified: self.profile_verification.companyVerified,
            domainVerified: self.profile_verification.domainVerified,
            status: status,
          };
          axios
              .post(this.updateUrlweb(this.module, self.editId), postData)
              .then(function (response) {
                self.submitted = false; //Enable Button
                if (response.data.code === 200) {
                  localStorage.setItem("showAlert", "This is my alert");
                  self.sendAlert();
                  self.$router.push({path: "/profile"});
                } else {
                  self.err_msg = response.data.response.message;
                  self.dismissCountDown = 10;
                }
              })
              .catch(function (error) {
                self.submitted = false; //Enable Button
              });
        }, 500);
      }
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/profile"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.imageDimenstion {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.hasError label {
  color: red;
}

.imageFormat {
  width: 100%;
}
</style>
